<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>劳动人员信息</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <!-- <el-button size="small" @click="gotoAdd">+添加信息</el-button> -->
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div>
                <div class="flex align-center">
                    <el-link type="primary" class="margin-right-sm" href="https://ftp.srrlzysc.com/be1fa2bf-f253-4e8c-9ceb-6c00e004d0ec.xlsx?a=1" target="_blank">模板下载</el-link>
                    <el-upload
                        class="upload-demo"
                        action="/api/labor/importFile"
                        :limit="1"
                        ref='upload'
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :headers="headers"
                        :on-success="onSuccess">
                        <el-button  type="primary" icon="el-icon-upload2" style="margin-right:8px;"  :loading="uploading">导入</el-button>
                    </el-upload>
                    <!--                    <el-button type="primary"  @click="exportaction" icon="el-icon-bottom" :loading="exportloading">导出</el-button>-->
                    <el-button  type="primary" class="mybotton" icon="el-icon-circle-plus-outline" @click="openadd">添加信息</el-button>
                </div>
            </div>
            <div class="search flex justify-between margin-top-sm">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchfrom" class="demo-form-inline">
                        <el-form-item label="关键词">
                            <el-input v-model="searchfrom.key" placeholder="关键词搜索" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-select v-model="searchfrom.sex" clearable style="width: 100px;" placeholder="请选择">
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="学历">
                            <el-select v-model="searchfrom.culture" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="硕士及以上" value="硕士及以上"></el-option>
                                <el-option label="本科" value="本科"></el-option>
                                <el-option label="大专" value="大专"></el-option>
                                <el-option label="高中（中专、技校）" value="高中（中专、技校）"></el-option>
                                <el-option label="初中及以下" value="初中及以下"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否就业">
                            <el-select v-model="searchfrom.isJob" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="务工地">
                            <el-input v-model="searchfrom.jobAddress" placeholder="务工地" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="工作岗位">
                            <el-input v-model="searchfrom.jobType" placeholder="务工地" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="未就业原因">
                            <el-input v-model="searchfrom.notWorkReason" placeholder="务工地" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="是否有就业意愿">
                            <el-select v-model="searchfrom.isNeedWork" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="期望从事行业">
                            <el-input v-model="searchfrom.wantWork" placeholder="务工地" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="月收入期望">
                            <el-select v-model="searchfrom.money" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="1000-3000" value="1000-3000"></el-option>
                                <el-option label="3000-5000" value="3000-5000"></el-option>
                                <el-option label="5000-8000" value="5000-8000"></el-option>
                                <el-option label="8000以上" value="8000以上"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否需要培训">
                            <el-select v-model="searchfrom.needTraining" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否有创业意愿">
                            <el-select v-model="searchfrom.needCreateWork" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="就业形式">
                            <el-select v-model="searchfrom.workSituation" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="全职" value="全职"></el-option>
                                <el-option label="灵活就业" value="灵活就业"></el-option>
                                <el-option label="个体工商" value="个体工商"></el-option>
                                <el-option label="务农" value="务农"></el-option>
                                <el-option label="事业单位" value="事业单位"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否个体经营">
                            <el-select v-model="searchfrom.isSelf" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否需要帮扶">
                            <el-select v-model="searchfrom.needHelp" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否有技能">
                            <el-select v-model="searchfrom.needSkill" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身份类别">
                            <el-select v-model="searchfrom.target" clearable style="width: 120px;" placeholder="请选择">
                                <el-option label="农村劳动力" value="农村劳动力"></el-option>
                                <el-option label="高校毕业生" value="高校毕业生"></el-option>
                                <el-option label="退役军人" value="退役军人"></el-option>
                                <el-option label="残疾人" value="残疾人"></el-option>
                                <el-option label="就业困难人员" value="就业困难人员"></el-option>
                                <el-option label="空白" value="空白"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit" class="mybotton">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>

            </div>
            <div class="list">
                <el-table
                    :data="list"
                    v-loading="loading"
                    border
                    height="520"
                    :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        fixed
                        prop="name"
                        width="100"
                        label="姓名">
                    </el-table-column>
                    <el-table-column
                        prop="age"
                        width="100"
                        label="年龄">
                    </el-table-column>
                    <el-table-column
                        prop="sex"
                        width="100"
                        label="性别">
                    </el-table-column>
                    <el-table-column
                        prop="idCard"
                        width="180"
                        label="身份证">
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        width="200"
                        label="户籍地址">
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        width="150"
                        label="手机号">
                    </el-table-column>
                    <el-table-column
                        prop="culture"
                        width="220"
                        label="学历">
                    </el-table-column>
                    <el-table-column
                        prop="major"
                        width="150"
                        label="专业">
                    </el-table-column>
                    <el-table-column
                        prop="graduationTime"
                        width="150"
                        label="毕业时间">
                    </el-table-column>
                    <el-table-column
                        prop="isJob"
                        width="150"
                        label="是否就业">
                    </el-table-column>
                    <el-table-column
                        prop="jobAddress"
                        width="150"
                        label="务工地">
                    </el-table-column>
                    <el-table-column
                        prop="industry"
                        width="150"
                        label="从事行业">
                    </el-table-column>
                    <el-table-column
                        prop="jobType"
                        width="150"
                        label="工作岗位">
                    </el-table-column>
                    <el-table-column
                        prop="notWorkReason"
                        width="150"
                        label="未就业原因">
                    </el-table-column>
                    <el-table-column
                        prop="isNeedWork"
                        width="150"
                        label="是否有就业意愿">
                    </el-table-column>
                    <el-table-column
                        prop="yearMoney"
                        width="150"
                        label="年收入">
                    </el-table-column>
                    <el-table-column
                        prop="wantWork"
                        width="150"
                        label="期望从事的行业">
                    </el-table-column>
                    <el-table-column
                        prop="money"
                        width="150"
                        label="月收入期望（元）">
                    </el-table-column>
                    <el-table-column
                        prop="needTraining"
                        width="150"
                        label="是否有培训意愿">
                    </el-table-column>
                    <el-table-column
                        prop="needCreateWork"
                        width="150"
                        label="是否有创业意愿">
                    </el-table-column>
                    <el-table-column
                        prop="workSituation"
                        width="150"
                        label="就业形式">
                    </el-table-column>
                    <el-table-column
                        prop="startTime"
                        width="150"
                        label="就业开始时间">
                    </el-table-column>
                    <el-table-column
                        prop="isSelf"
                        width="150"
                        label="是否个体经营">
                    </el-table-column>
                    <el-table-column
                        prop="companyName"
                        width="150"
                        label="就业企业名称">
                    </el-table-column>
                    <el-table-column
                        prop="needHelp"
                        width="150"
                        label="是否需要帮扶">
                    </el-table-column>
                    <el-table-column
                        prop="needSkill"
                        width="150"
                        label="是否有技能">
                    </el-table-column>
                    <el-table-column
                        prop="skillType"
                        width="150"
                        label="技能种类">
                    </el-table-column>
                    <el-table-column
                        prop="target"
                        width="150"
                        label="身份类别">
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        width="150"
                        label="备注">
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        fixed="right"
                        width="200"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"  @click="openedit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="delItem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up :title="status==1?'添加':'编辑'" width="35%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="姓名">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="年龄">
                        <el-input v-model="form.age"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-select v-model="form.sex" placeholder="请选择">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份证">
                        <el-input :disabled="status==1?false:true" v-model="form.idCard"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="form.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label=" 户籍所在地">
                        <el-input v-model="form.address"></el-input>
                    </el-form-item>
                    <el-form-item label="学历">
                        <el-input v-model="form.culture"></el-input>
                    </el-form-item>
                    <el-form-item label="专业">
                        <el-input v-model="form.major"></el-input>
                    </el-form-item>
                    <el-form-item label="毕业时间">
                        <el-input v-model="form.graduationTime"></el-input>
                    </el-form-item>
                    <el-form-item label="是否就业">
                        <el-select v-model="form.isJob" placeholder="请选择">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="务工地">
                        <el-input v-model="form.jobAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="从事行业">
                        <el-input v-model="form.industry"></el-input>
                    </el-form-item>
                    <el-form-item label="工作岗位">
                        <el-input v-model="form.jobType"></el-input>
                    </el-form-item>
                    <el-form-item label="未就业原因">
                        <el-input v-model="form.notWorkReason"></el-input>
                    </el-form-item>
                    <el-form-item label="是否有就业意愿">
                        <el-radio v-model="form.isNeedWork" label="有"></el-radio>
                        <el-radio v-model="form.isNeedWork" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="年收入">
                        <el-input v-model="form.yearMoney"></el-input>
                    </el-form-item>
                    <el-form-item label="期望从事的行业">
                        <el-input v-model="form.wantWork"></el-input>
                    </el-form-item>
                    <el-form-item label="月收入期望（元）">
                        <el-select v-model="form.money" placeholder="请选择">
                            <el-option label="1000-3000" value="1000-3000"></el-option>
                            <el-option label="3000-5000" value="3000-5000"></el-option>
                            <el-option label="5000-8000" value="5000-8000"></el-option>
                            <el-option label="8000元以上" value="8000元以上"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有培训意愿">
                        <el-radio v-model="form.needTraining" label="有"></el-radio>
                        <el-radio v-model="form.needTraining" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="是否有创业意愿">
                        <el-radio v-model="form.needCreateWork" label="有"></el-radio>
                        <el-radio v-model="form.needCreateWork" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="就业形式">
                        <el-select v-model="form.workSituation" placeholder="请选择">
                            <el-option label="全职" value="全职"></el-option>
                            <el-option label="灵活就业" value="灵活就业"></el-option>
                            <el-option label="个体工商" value="个体工商"></el-option>
                            <el-option label="务农" value="务农"></el-option>
                            <el-option label="事业单位" value="事业单位"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="就业开始时间">
                        <el-input v-model="form.startTime"></el-input>
                    </el-form-item>
                    <el-form-item label="是否个体经营">
                        <el-radio v-model="form.isSelf" label="是"></el-radio>
                        <el-radio v-model="form.isSelf" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="就业企业名称">
                        <el-input v-model="form.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="是否需要帮扶">
                        <el-radio v-model="form.needHelp" label="是"></el-radio>
                        <el-radio v-model="form.needHelp" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="是否有技能">
                        <el-radio v-model="form.needSkill" label="是"></el-radio>
                        <el-radio v-model="form.needSkill" label="否"></el-radio>
                    </el-form-item>
                    <el-form-item label="技能种类">
                        <el-input v-model="form.skillType"></el-input>
                    </el-form-item>
                    <el-form-item label="身份类别">
                        <el-select v-model="form.target" placeholder="请选择">
                            <el-option label="农村劳动力" value="农村劳动力"></el-option>
                            <el-option label="高校毕业生" value="高校毕业生"></el-option>
                            <el-option label="退役军人" value="退役军人"></el-option>
                            <el-option label="残疾人" value="残疾人"></el-option>
                            <el-option label="就业困难人员" value="就业困难人员"></el-option>
                            <el-option label="空白" value="空白"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="form.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/booth'
import _api from '@/api/index'
import axios from "axios";
export default {
    data(){
        return{
            headers:{
                AuthorToken:JSON.parse(window.localStorage.getItem('token'))
            },
            searchfrom:{
                key:null,
                sex:null,
                culture:null,
                isJob:null,
                jobAddress:null,
                jobType:null,
                notWorkReason:null,
                isNeedWork:null,
                wantWork:null,
                money:null,
                needTraining:null,
                needCreateWork:null,
                workSituation:null,
                isSelf:null,
                needHelp:null,
                needSkill:null,
                target:null,

            },
            list:[],
            total:0,
            pageNo:1,
            pageSize:10,
            loading:false,
            uploading:false,
            showpop:false,
            exportloading:false,
            status:1,
            options:[],
            form:{
                id:null,
                name:null,
                age:null,
                sex:null,
                idCard:null,
                mobile:null,
                address:null,
                culture:null,
                major:null,
                graduationTime:null,
                isJob:null,
                jobAddress:null,
                industry:null,
                jobType:null,
                notWorkReason:null,
                isNeedWork:null,
                yearMoney:null,
                wantWork:null,
                money:null,
                needTraining:null,
                needCreateWork:null,
                workSituation:null,
                startTime:null,
                isSelf:null,
                companyName:null,
                needHelp:null,
                needSkill:null,
                skillType:null,
                target:null,
                remark:null,

            }
        }
    },
    created() {
        this.getList()
        this.getOptions()
    },
    methods:{
        onSuccess(response, file, fileList){
            this.uploading=false
            console.log(response)
            this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
            if(response.code==200){
                this.$message.success("导入成功！")
                this.getList()
            }else{
                this.$message.warning(response.message)
            }
        },
        beforeUpload(file){
            let maxsize=5*1024*1024
            // console.log(file.size)
            // if(file.size>maxsize){
            //     this.$message.warning("文过不能超过5M!")
            //     return false
            // }

            this.uploading=true
            return true
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        getList(){
            this.loading=true
            _api.get(serverurl.laborlist,{page:this.pageNo,size:this.pageSize,...this.searchfrom}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        delItem(row){

            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.delete(serverurl.labor,row.id).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        this.getList()
                    }
                })
            }).catch(() => {

            });


        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.status=1
            this.showpop=true
        },
        getconfirm(){
            if(!this.form.name){
                this.$message.warning("姓名不能为空！")
                return
            }
            if(!this.form.mobile){
                this.$message.warning("手机不能为空！")
                return
            }
            if(!this.form.idCard){
                this.$message.warning("身份证不能为空！")
                return
            }
            if(!this.form.target){
                this.$message.warning("请选择标签！")
                return
            }
            let apitype=this.status==1?'post':'put'
            _api[apitype](serverurl.labor,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.warning(res.message)
                }
            })
        },
        getclose(){
            this.showpop=false
            this.status=1
            this.form={
                id:null,
                name:null,
                age:null,
                sex:null,
                idCard:null,
                mobile:null,
                address:null,
                culture:null,
                major:null,
                graduationTime:null,
                isJob:null,
                jobAddress:null,
                industry:null,
                jobType:null,
                notWorkReason:null,
                isNeedWork:null,
                yearMoney:null,
                wantWork:null,
                money:null,
                needTraining:null,
                needCreateWork:null,
                workSituation:null,
                startTime:null,
                isSelf:null,
                companyName:null,
                needHelp:null,
                needSkill:null,
                skillType:null,
                target:null,
                remark:null,
            }
        },
        getOptions(){
            _api.get(serverurl.laborgetTargetList).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.options=res.data
                }
            })
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>


